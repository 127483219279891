import {hillo} from 'hillo'
import {orderBy} from "lodash-es";
import GlobalConfig from "../GlobalSettings";

export async function loadOrders() {
    return orderBy((await hillo.get('Orders.php?op=getTodayPickUpOrder')).content.map(t => {
        t.name = t.name ?? 'Table not exists'
        return t
    }), ['dailySerial'], ['asc'])
}

export async function pushOrder(order) {
    if (order.pickStatus < 2) {
        return (await changeOrderStatus(order.id, parseInt(order.pickStatus) + 1))
    }

}

export async function backFinishOrder(order) {
    return (await changeOrderStatus(order.id, 0))
}


export async function recallOrder(order) {
    return (await changeOrderStatus(order.id, 1))
}


export async function changeOrderStatus(id, status) {
    return (await hillo.post('Orders.php?op=changeOrderPickUpStatus', {
        orderId: id,
        status: status
    })).content
}

export async function changeOrderListStatus(idList) {
    const request = []
    for (const id of idList) {
        request.push(hillo.post('Orders.php?op=changeOrderPickUpStatus', {
            orderId: id,
            status: 2,
        }))
    }
    return Promise.all(request)
}

export async function showPickUpBannerImages() {
    return await hillo.get('Restaurant.php?op=showGiraffeBannerImage').content
}

export async function getRestaurantInfo() {
    return await hillo.get('Restaurant.php?op=view')
}


export async function checkConnection() {
    try {
        const res = await hillo.get('AccessLog.php?op=deviceId')
        console.log(res)
        GlobalConfig.soundDuration = await forceGetSystemSetting({
            section: 'fastPick',
            sKey: 'useSoundDuration',
            sValue: 'short',
            defaultValue: 'short',
            sType: 'options',
            minimumVersion: '1.7.825',
            sOptions: 'short,long',
            tagList: 'basic,FastPick'
        })
        if (res.status === 'good') {
            return true
        }
    } catch (e) {
        console.log(e)
    }
    return false
}

export async function forceGetSystemSetting (item) {
    return (await hillo.post('Restaurant.php?op=forceGetSystemSetting', {
        systemSetting: JSON.stringify(item)
    })).content
}
