<template>
  <v-app>
    <v-main app>
      <Home v-if="!$vuetify.breakpoint.mobile" @refresh="reloadOrders" :orders="orders"/>
      <Mobile v-else @refresh="reloadOrders" :orders="orders"></Mobile>
    </v-main>

  </v-app>
</template>

<script>

import {loadOrders} from '@/service/api'
import GlobalConfig, {hardReload, useCurrentConfig} from '@/GlobalSettings'
import Home from '@/views/Home'
import {version} from '../package.json'
import Mobile from "@/views/Mobile.vue";

export default {
  name: 'App',
  components: {Mobile, Home},
  data: () => ({
    orders: [],
    version: version,
    Config: GlobalConfig,
    item: [
      'ZH',
      'EN',
      'DE',
    ],
  }),
  methods: {
    async reloadOrders() {
      this.orders = await loadOrders()
    },
    useCurrentConfig,
    hardReload,
  },
  async mounted() {

    await this.reloadOrders()
    const t = setInterval(this.reloadOrders, 3000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(t)
    })
  },
}
</script>

<style>
.mobilText {
  font-size: 24px;
  height: 40px;
}

</style>

