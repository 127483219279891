<template>
  <div>
    <div>
      <v-system-bar app>
        <div>Aaden POS | Queue v{{version}}</div>
      </v-system-bar>
      <div v-if="tab ===1">
          <v-card @click="clearAll" outlined height="40px" style="width: 100%;background-color: #a6d3ff" class="d-flex align-center justify-center"><v-icon>mdi-send-outline</v-icon></v-card>
<!--          <v-card outlined height="40px" style="width: 100%;background-color: #a6d3ff" class="d-flex align-center justify-center">一键清空</v-card>-->
      </div>
      <div v-for="o in shouldShowOrders"
           class="ma-1 d-flex grey lighten-5 align-center"
           :key="o.id"
      >
        <v-card :color="o.pickStatus==='0'?'amber':'success'" tile elevation="0"
                class="d-flex justify-center align-center lighten-5"
                height="96" width="96">
          <div class="text-h4">
            #{{ o.dailySerial ? o.dailySerial : o.name }}
          </div>
        </v-card>
        <div class="pa-6">
          <div class="text-body-1 font-weight-black">{{ o.totalPrice }}€</div>
          <div class="text-body-2">{{ dayjs(o.updateTimestamp).fromNow() }}</div>
        </div>
        <v-spacer></v-spacer>
        <v-card @click="pushOrder(o)" color="grey lighten-4" tile elevation="0"
                class="d-flex justify-center align-center flex-column"
                height="96" width="72">
          <template v-if="o.pickStatus==='0'">
            <v-icon>mdi-cup-water</v-icon>
          </template>
          <template v-else-if="o.pickStatus==='1'">
            <v-icon>mdi-basket</v-icon>
          </template>
          <template v-else>
            <v-icon>mdi-backup-restore</v-icon>
          </template>
        </v-card>
        <v-card v-if="o.pickStatus==='2'" @click="recallOrder(o)" color="grey lighten-4" tile elevation="0"
                class="d-flex justify-center align-center flex-column ml-2"
                height="96" width="72">
          <template>
            <v-icon>mdi-bell-outline</v-icon>
          </template>
        </v-card>
      </div>
    </div>
    <v-bottom-navigation background-color="grey lighten-4" height="64" app grow shift v-model="tab">
      <v-btn height="64">
        <span>All</span>
        <v-icon>mdi-list-box</v-icon>
      </v-btn>

      <v-btn height="64" color="amber lighten-4">
        <span>Preparing</span>
        <v-icon>mdi-cup-water</v-icon>
      </v-btn>

      <v-btn height="64" color="green lighten-4">
        <span>Togo</span>
        <v-icon>mdi-basket</v-icon>
      </v-btn>
      <v-btn height="64" color="red lighten-4">
        <span>Restore</span>
        <v-icon>mdi-backup-restore</v-icon>
      </v-btn>
    </v-bottom-navigation>

  </div>
</template>

<script>
import {backFinishOrder, changeOrderListStatus, pushOrder, recallOrder} from '@/service/api'
import {dragscroll} from 'vue-dragscroll'
import dayjs from "dayjs";
import {sortBy} from "lodash-es";

const {version} = require('../../package.json')

export default {
  directives: {
    dragscroll
  },
  name: 'Mobile',
  data: () => ({
    focusDialog: false,
    tab: 0,
    notice: "",
    version
  }),
  props: {
    orders: {
      type: Array
    },
    dark: {
      type: Boolean
    }

  },
  computed: {
    dayjs() {
      return dayjs
    },
    shouldShowOrders() {
      const filter = (t) => {
        let canShow = t.consumeTypeStatusId > 5

        if (this.tab === 0) {
          canShow = canShow && t.pickStatus < 2
        } else if (this.tab === 1) {
          canShow = canShow && t.pickStatus === '0'
        } else if (this.tab === 2) {
          canShow = canShow && t.pickStatus === '1'
        } else if (this.tab === 3) {
          canShow = canShow && t.pickStatus > 1
        }
        return canShow
      }
      return sortBy(this.orders.filter(filter), 'dailySerial')
    },
  },
  methods: {
    async clearAll() {
      const idList = this.shouldShowOrders.filter(it => it.pickStatus === '0').map(it => it.id)
      await changeOrderListStatus(idList)
    },
    async recallOrder (order) {
      await recallOrder(order)
    },
    async pushOrder(order) {
      if (order.pickStatus < 2) {
        await pushOrder(order)
      } else {
        await backFinishOrder(order)
      }
      this.$emit('refresh')
    },
  }


}
</script>

<style scoped>


</style>
