import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import GlobalConfig, { loadConfig } from '@/GlobalSettings'
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import de from 'dayjs/locale/de'
dayjs.locale(de) // use locale globally
Vue.config.productionTip = false

dayjs.extend(relativeTime)
import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)
async function initial () {
  await loadConfig()
  i18n.locale=GlobalConfig.lang.toLowerCase()
  new Vue({
    i18n,
    vuetify,
    router,
    render: h => h(App)
  }).$mount('#app')

}

initial()

