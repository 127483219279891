<template>
  <div style="overflow: hidden;">
    <div style="position: relative;overflow: hidden">
      <div v-if="imgUrl" :style="{right:'36px'}" style="position: fixed;bottom:36px; overflow: visible;z-index: 21">
        <v-img contain width="128" :src="imgUrl"></v-img>
      </div>
      <div style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr))">
        <v-card elevation="0" tile :color="i.status?'grey lighten-5':''" class="pa-6"
                style="width: 100%;height: 100vh;overflow: hidden" :key="i.status" v-for="i in info">
          <div class="text-h1 font-weight-black mb-4 mt-n2 text-no-wrap" style="font-size: 4rem !important;">
            {{ $t(i.header) }}
          </div>
          <div class="mt-4">
            <div
                :style="i.status?
                  'grid-template-columns: repeat(4,minmax(0,1fr));':
                  'grid-template-columns: repeat(4,minmax(0,1fr));'"
                style="display: grid;overflow: hidden;grid-gap:24px;width: 100%">
              <v-card
                  elevation="0"
                  tile
                  :color="!i.status?'amber lighten-4 black--text':'grey lighten-3'"
                  v-dragscroll
                  :style="!i.status&&index===0?'grid-column:span 4':''"
                  class="px-4 align-center d-flex justify-center font-weight-black"
                  v-for="(o,index) in i.status?making:togo"
                  :key="o.id"
                  @click.stop="pushOrder(o)"
                  style=" font-size: 4rem;border-radius: 36px !important;"
              >
                #{{
                  o.dailySerial ? o.dailySerial : o.name
                }}
              </v-card>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="focusDialog" fullscreen>
      <v-card tile color="amber lighten-5" class="d-flex flex-column">
        <div class="flex-grow-1" style="display: grid;grid-template-columns:70% 30%;height: 100%">
          <div class="d-flex align-center flex-column justify-center">
            <div>
              <span style="font-size: 12rem">Nr.</span><span style="font-size: 24rem;" class="font-weight-black">{{
                notice
              }}</span>
            </div>
          </div>
          <div style="overflow-y: hidden;height: 100vh" class="white">
            <v-card
                class="pa-4 d-flex justify-center align-center"
                elevation="0"
                :color="t.dailySerial===notice?'black white--text':'white'"
                v-for="t in togo" :key="t.id" style="font-size: 4rem">
              {{ t.dailySerial ? t.dailySerial : t.name }}
              <v-spacer></v-spacer>
              <div style="font-size: 2rem" class="font-weight-black ml-8">
                {{ dayjs(firstPickUpaTimestamp[t.dailySerial]).fromNow() }}
              </div>

            </v-card>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="initDialog" fullscreen>
      <v-card tile :color="connectionOk?'indigo':'error'" dark style="position: relative">
        <div style="position: absolute;bottom: 32px;left: 0;right: 0;margin: auto;width: fit-content">
          Aaden Queue@v{{ version }}
        </div>
        <div class="d-flex align-center justify-center flex-column" style="height: 100vh">
          <template v-if="connectionOk">
            <div class="text-h6">
              You are connecting to Aaden POS in
            </div>
            <div class="text-h2 mt-4 font-weight-black mr-8">
              <v-icon left color="white" size="48" class="mr-8 mt-n3">mdi-wifi-check</v-icon>
              {{ Config.Base }}
            </div>
            <v-card @click="initDialog=false" light
                    v-if="connectionOk"
                    :disabled="!connectionOk"
                    class="pa-4 px-16 mt-6 d-flex align-center justify-center font-weight-black text-h4"
                    style="border-radius: 48px !important;">
              Start
            </v-card>
          </template>
          <template v-else>
            <div class="text-h6 text-center">
              We can't connect Aaden POS at following IP<br> Please check your configuration
            </div>
            <div class="text-h2 mt-4 font-weight-black mr-8">
              <v-icon left color="white" size="48" class="mr-8 mt-n3">mdi-wifi-check</v-icon>
              {{ Config.Base }}
            </div>
            <v-card @click="initDialog=false" light
                    v-if="connectionOk"
                    :disabled="!connectionOk"
                    class="pa-4 px-16 mt-6 d-flex align-center justify-center font-weight-black text-h4"
                    style="border-radius: 48px !important;">
              Start
            </v-card>
            <v-card @click="initDialog=false" dark
                    elevation="0"
                    :disabled="!connectionOk"
                    class="pa-4 px-16 mt-6 d-flex align-center justify-center font-weight-black text-h4"
                    style="border-radius: 48px !important;">
              Network Failed
            </v-card>


          </template>


        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {checkConnection, getRestaurantInfo, pushOrder} from '@/service/api'
import GlobalConfig from '@/GlobalSettings'
import {dragscroll} from 'vue-dragscroll'
import {Howl} from 'howler';
import dayjs from "dayjs";
import {intersection} from "lodash-es";

const {version} = require('../../package.json')

const sleep = (time = 5 * 1000) => new Promise(resolve => setTimeout(resolve, time))
let soundStore = []
export default {
  directives: {
    dragscroll
  },
  name: 'Home',
  data: () => ({
    version,
    imgUrl: null,
    focusDialog: true,
    queueLock: false,
    timer: null,
    displayQueue: [],
    firstPickUpaTimestamp: {},
    notice: "",
    info: [
      {header: 'Preparing', status: true},
      {header: 'Ready', status: false}
    ],
    Config: GlobalConfig,
    initDialog: true,
    connectionOk: false,
  }),
  props: {
    orders: {
      type: Array
    },
    dark: {
      type: Boolean
    }

  },
  computed: {
    making() {
      return this.orders.filter(o => parseInt(o.pickStatus) === 0).filter(t => t.consumeTypeStatusId > 5)
    },
    togo() {
      return this.orders.filter(o => parseInt(o.pickStatus) === 1)
    }
  },
  watch: {
    async togo(val) {
      if (!this.$vuetify.breakpoint.mobile) {
        const ids = val.map(it => it.id)
        soundStore = intersection(ids,soundStore)
        const notInStore = val.filter(it => !soundStore.includes(it.id))
        if (notInStore.length > 0) {
          for (const it of notInStore) {
            soundStore.push(it.id)
          }
          if (!this.initDialog) {
            for (const it of notInStore) {
              this.displayQueue.push(it.dailySerial)
            }
          }

        }
      }

    }
  },
  async mounted() {
    this.connectionOk = (await checkConnection())
    await this.getImages()
    this.timer = setInterval(() => {
      this.doQueue()
    }, 1000)

  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    dayjs,
    async doQueue() {
      if (!this.queueLock && !this.initDialog) {
        this.queueLock = true
        while (this.displayQueue.length > 0) {
          const serial = this.displayQueue.shift()
          this.firstPickUpaTimestamp[serial] = dayjs().valueOf()
          this.doSpeak(serial)
          await this.showFocusDialog(serial)
        }
        this.queueLock = false
      }
    },
    doSpeak(number) {
      try {
        if (GlobalConfig.soundDuration === 'long') {
          new Howl({src: ['longSound.mp3']}).play()
        } else {
          new Howl({src: ['shortSound.mp3']}).play()
        }
        let speechInstance = new SpeechSynthesisUtterance('Nummer ' + number + ' Bitte nehmen Sie die Bestellung');
        speechInstance.lang = 'de'
        speechSynthesis.speak(speechInstance);
      } catch (e) {
        console.log(e)
      }

    },
    async showFocusDialog(number) {
      this.notice = number
      this.focusDialog = true
      await sleep(GlobalConfig.displayTime * 1000)
      this.focusDialog = false
    },
    async pushOrder(order) {
      await pushOrder(order)
      this.$emit('refresh')
    },
    async getImages() {
      const res = await getRestaurantInfo()
      const resInfo = res.content[0]
      if (resInfo.buffetLogo) {
        this.imgUrl = GlobalConfig.Protocol + GlobalConfig.Base + '/' + resInfo.buffetLogo
      }
    }
  }


}
</script>

<style scoped>


@keyframes mymove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2500px);
  }
}

/*mobilText*/
/*App.vue setting*/
</style>
